import moment from "moment";
export const limitDateMixin = {
    computed: {
        maxDate() {
            let lastDay = this.getDay(0);
            let newDate = `${this.$route.params.year}-${this.$route.params.month}-${lastDay}`;
            return moment(newDate).format("YYYY-MM-DD");
        },
        minDate() {
            let firstDay = this.getDay(1);
            let newDate = `${this.$route.params.year}-${this.$route.params.month}-${firstDay}`;
            return moment(newDate).format("YYYY-MM-DD");
        },
    },
    methods: {
        getDay(dayNo) {
            let day = new Date(
                this.$route.params.year,
                this.$route.params.month,
                dayNo
            ).getDate();
            return day;
        },
    },
};
