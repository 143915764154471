<template>
  <v-card class="card--shadow">
    <v-card-title class="purple accent-4">
      <div class="subtitle-1 white--text">Supervision Visit Form</div>
    </v-card-title>
    <v-card-text class="pt-4">
      <form-require-noti />
      <v-row>
        <v-col lg="3">
          <label>Patient's Township <require-sign /></label>
                <v-select
                :items="townships"
                v-model="supervisionData.patient_township_id"
                item-text="name"
                item-value="id"
                dense
                outlined
                ></v-select>
        </v-col>
        <v-col lg="3">
          <label>Code <require-sign /></label>
          <small class="text-muted">&nbsp; (eg. 99 or 2)</small>
          <v-text-field
            v-model="supervisionData.mdrtb_code"
            :error-messages="handleFieldErrors($v.supervisionData.mdrtb_code)"
            hide-details
            type="text"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col lg="3">
          <label>Year <require-sign /></label>
          <small class="text-muted">&nbsp; (eg. 24)</small>
          <v-text-field
            v-model="supervisionData.mdrtb_year"
            :error-messages="handleFieldErrors($v.supervisionData.mdrtb_year)"
            hide-details
            type="text"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col lg="3">
          <label>Mono-Poly/SG<require-sign /></label>
        <v-select
          v-model="supervisionData.mdrtb_monopoly"
          :items="[
            { id: 1, text: 'No' },
            { id: 2, text: 'Yes' },
            { id: 3, text: 'SG' },
          ]"
          :error="$v.supervisionData.mdrtb_monopoly.$error"
          item-text="text"
          item-value="id"
          dense
          outlined
        ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col lg="3">
          <label>Volunteer's Township <require-sign /></label>
                <v-select
                :items="townships"
                v-model="supervisionData.volunteer_township_id"
                item-text="name"
                item-value="id"
                @change="handleSupportingTownship"
                dense
                outlined
                ></v-select>
        </v-col>
        <v-col lg="3">
          <label>Volunteer <require-sign /></label>
          <v-select
            :items="volunteers"
            v-model="supervisionData.volunteer_id"
            @input="changeVolunteer"
            :error-messages="handleFieldErrors($v.supervisionData.volunteer_id)"
            item-value="id"
            dense
            outlined
          >
            <template v-slot:selection="{ item }">
              <span>{{ item.name }} / {{ item.code }}</span>
            </template>
            <template v-slot:item="{ item }">
              <span>{{ item.name }} / {{ item.code }}</span>
            </template>
          </v-select>
        </v-col>
        <v-col lg="3">
          <label>DOT Type <require-sign /></label>
          <v-select
            :items="[
              { value: 1, text: `Through DOT application` },
              { value: 2, text: `By DOT Card` },
            ]"
            v-model="supervisionData.dots_type"
            :error-messages="handleFieldErrors($v.supervisionData.dots_type)"
            item-text="text"
            item-value="value"
            dense
            outlined
          ></v-select>
        </v-col>
        <v-col lg="3">
          <label>Supervision Type <require-sign /></label>
          <v-select
            :items="[
              { value: 1, text: `In person` },
              { value: 2, text: `By Phone` },
            ]"
            v-model="supervisionData.supervision_type"
            :error-messages="
              handleFieldErrors($v.supervisionData.supervision_type)
            "
            item-text="text"
            item-value="value"
            dense
            outlined
          ></v-select>
        </v-col>
       
      </v-row>
      <v-row>
        <v-col lg="6">
          <label>Date <require-sign /></label>
          <!-- <date-picker
            @clearDate="supervisionData.date = null"
            :validationMsg="handleFieldErrors($v.supervisionData.date)"
            :date="supervisionData.date"
            v-model="supervisionData.date"
            :min="minDate"
            :max="maxDate"
          /> -->
          <date-picker
              @clearDate="supervisionData.date = null"
              :date="supervisionData.date"
              v-model="supervisionData.date"
            />
        </v-col>
      </v-row>
      <v-divider />
      <v-simple-table dense>
        <tbody>
          <tr
            v-for="result in supervisionData.radio_value_list"
            :key="result.id"
          >
            <td width="70%">{{ showText(result.id) }}</td>
            <td>
              <v-radio-group
                class="my-2"
                v-model="result[Object.keys(result)[1]]"
                hide-details
                dense
                row
              >
                <v-radio label="Yes" value="2"></v-radio>
                <v-radio label="No" value="1"></v-radio>
              </v-radio-group>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-divider />
      <v-row>
        <v-col class="ml-3 mt-3" lg="8">
          <label>Remark</label>
          <v-text-field
            v-model.trim="supervisionData.remark"
            type="text"
            filled
            dense
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="px-4 pb-4">
      <v-spacer></v-spacer>
      <v-btn @click.prevent="emitData" class="form-btn">
        <v-icon left>mdi-chevron-right-circle-outline</v-icon>Submit
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { ValidationMixin } from "../../mixins/ValidationMixin";
import { limitDateMixin } from "../../mixins/limitDateMixin";
import { mapState, mapActions } from "vuex";
import FormRequireNoti from "@/components/notification/FormRequireNoti.vue";
import RequireSign from "@/components/notification/RequireSign.vue";
import DatePicker from "@/components/common/DatePicker.vue";

export default {
  components: { FormRequireNoti, RequireSign, DatePicker },
  mixins: [ValidationMixin, limitDateMixin],
  props: {
    supervisionData: {
      type: Object,
      default: () => {
        return {
          mdrtb_monopoly:null,
          mdrtb_code: null,
          mdrtb_year: null,
          dots_type: null,
          supervision_type: null,
          township_id:null,
          patient_township_id:null,
          volunteer_township_id:null,
          remark: null,
          radio_value_list: [
            { id: 1, value_1: "1" },
            { id: 2, value_2: "1" },
            { id: 3, value_3: "1" },
            { id: 4, value_4: "1" },
            { id: 5, value_5: "1" },
            { id: 6, value_6: "1" },
            { id: 7, value_7: "1" },
            { id: 8, value_8: "1" },
            { id: 9, value_9: "1" },
            { id: 10, value_9: "1" },
            { id: 11, value_10: "1" },
          ],
        };
      },
    },
  },
  data: () => ({
    menuDate: null,
    radioText: [
      { id: 1, text: "Performing DOTS regulary" },
      { id: 2, text: "Complete Recording" },
      { id: 3, text: "Wearing N95" },
      { id: 4, text: "Health Education" },
      { id: 5, text: "Using Relevant IEC materials" },
      { id: 6, text: "Has Relevant TB knowledge to share" },
      { id: 7, text: "Side Effect monitoring" },
      { id: 8, text: "Systematic Contact Investigation" },
      { id: 9, text: "Good Communication and Relationship" },
      { id: 10, text: "Good Linkage" },
      { id: 11, text: "Counseling" },
    ],
  }),
  validations: {
    supervisionData: {
      mdrtb_code: { required },
      mdrtb_year: { required },
      mdrtb_monopoly:{required},
      patient_township_id:{required},
      volunteer_township_id:{required},
      volunteer_id: { required },
      dots_type: { required },
      supervision_type: { required },
      date: { required },
    },
  },
  computed: {
    ...mapState("Loading", ["loading"]),
    ...mapState("Volunteer", ["volunteers"]),
    ...mapState("Patient", ["patients"]),
    ...mapState("InfoData", [ "townships"]),
    ...mapState("Admin", ["admin"]),
  },
  methods: {
    ...mapActions("InfoData", ["getAllTownships"]),
    changeVolunteer() {
      if (this.supervisionData.volunteer_id) {
        this.$store.dispatch("Patient/getPatientsByVolunteer", {
          volunteer_id: this.supervisionData.volunteer_id,
        });
      }
    },
    handleSupportingTownship(id) {
      this.$store.dispatch("Volunteer/getVolunteerByTwnId", id);
    },
    showText(id) {
      let resultText = this.radioText.find((e) => e.id == id);
      if (resultText) return resultText.text;
    },
    emitData() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let data = {
          volunteer_id: this.supervisionData.volunteer_id,
          dots_type: this.supervisionData.dots_type,
          supervision_type: this.supervisionData.supervision_type,
          date: this.supervisionData.date,
          radio_value_list: this.supervisionData.radio_value_list,
          remark: this.supervisionData.remark,
          month: this.$route.params.month,
          year: this.$route.params.year,
          mdrtb_code: this.supervisionData.mdrtb_code,
          mdrtb_year: this.supervisionData.mdrtb_year,
          mdrtb_monopoly:this.supervisionData.mdrtb_monopoly,
          township_id:this.admin.township_id,
          patient_township_id:this.supervisionData.patient_township_id,
          volunteer_township_id:this.supervisionData.volunteer_township_id,
        };

       // const threeDigitPattern = /^[0-9]{3}$/;
        const twoDigitPattern = /^.*[1-9]{2}$/;
        if (
          twoDigitPattern.test(this.supervisionData.mdrtb_year)
        ) {
          this.$emit("emitData", data);
        } else {
          // if (!threeDigitPattern.test(this.supervisionData.mdrtb_code))
          //   this.$toast.error(
          //     "code must has at least three digit."
          //   );
          
          if (!twoDigitPattern.test(this.supervisionData.mdrtb_year))
            this.$toast.error("year must has at least two digit.");
          this.clearToastErrors();
        }
      }
    },
  },
  created() {
    this.$store.dispatch("Volunteer/getVolunteers");
    
    //  this.$store.dispatch(
    //   "Volunteer/getVolunteerByTwnId",
    //   !this.supervisionData.patient_township_id? Number( this.admin.township_id):this.supervisionData.patient_township_id,
    // );
    this.getAllTownships();
    this.supervisionData.patient_township_id=!this.supervisionData.patient_township_id? Number( this.admin.township_id):this.supervisionData.patient_township_id;
    this.supervisionData.volunteer_township_id=!this.supervisionData.volunteer_township_id? Number( this.admin.township_id):this.supervisionData.volunteer_township_id;
    this.supervisionData.township_id= Number( this.admin.township_id);
  },
  beforeMount() {
    this.supervisionData.date = !this.supervisionData.date ? this.minDate
      : this.supervisionData.date;
  },
};
</script>

<style scoped></style>
