<template>
  <div>
    <back-btn />
    <v-container fluid>
      <v-row justify="center">
        <v-col lg="10">
          <v-card class="card--shadow">
            <supervision-form @emitData="createSupervision" />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SupervisionForm from "@/components/supervision/SupervisionForm";
import BackBtn from "@/components/navigation/BackBtn.vue";

export default {
  components: {
    SupervisionForm,
    BackBtn,
  },
  computed: {},
  methods: {
    createSupervision(data) {
      this.$store.dispatch("SuperVision/create", data).then((response) => { 
        
           if(response!=false)
               {
                //this.$toast.success('Success');
                this.$router.push(
                    `/supervision-visit/activity/${this.$route.params.month}/${this.$route.params.year}`
                    );
               

               }else{
                
                 this.$toast.error('Validation Failed!');
               }
         });
      // this.$store.dispatch("SuperVision/create", data).then(() => {
      //   this.$router.push(
      //     `/supervision-visit/activity/${this.$route.params.month}/${this.$route.params.year}`
      //   );
      // });
    },
  },
};
</script>
