<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        outlined
        dense
        readonly
        persistent-hint
        prepend-inner-icon="mdi-calendar"
        :value="date"
        :error-messages="validationMsg"
         :placeholder="placeholder"
        :error="has_error"
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <v-date-picker
      :min="min"
      :max="max"
      ref="picker"
      @input="updateDate"
      @click:date="onChange"
      no-title
      v-bind="$attrs"
    >
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="$emit('clearDate')"> Clear </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    date: String,
    validationMsg: Array,
    has_error: Boolean,
    min: String,
    max: String,
    placeholder:String
  },
  methods: {
    onChange(date){
      this.$emit('onChange',date)
    },
    updateDate() {
      this.$emit("input", this.$refs.picker.inputDate);
      this.menu = false;
    },
  },
  data: () => ({
    menu: null,
  }),
};
</script>


<style scoped>
</style>