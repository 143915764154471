<template>
  <v-btn color="purple" @click="$router.go(-1)" text>
    <v-icon left> mdi-arrow-left-thin-circle-outline </v-icon>
    go Back
  </v-btn>
</template>

<script>
export default {};
</script>
